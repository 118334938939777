<template>
  <div>
    <b-container fluid>
      <b-row v-if="selectedDate === ''">
        <b-col md="4" sm="6" xs="12" class="mt-2 mb-2">
          <b-form-radio-group
            id="displayOptions"
            v-model="displayPeriodUom"
            label="Kalender weergave"
            label-for="displayOptions"
            class="mb-3"
          >
            <b-form-radio value="week">Week</b-form-radio>
            <b-form-radio value="month">Maand</b-form-radio>
          </b-form-radio-group>
        </b-col>

        <b-col md="3" sm="6" xs="12" offset-md="5" class="mt-2 mb-2">
          <font-awesome-icon
            v-if="loading"
            class="fa-spin"
            style="position: fixed; right: 50px; top: 17px;"
            far
            icon="spinner"
          />
          <b-form-select
            v-model="warehouseID"
            :options="warehouses"
          ></b-form-select>
        </b-col>
      </b-row>
      <div v-if="selectedDate">
        <b-button
          class="float-right"
          variant="primary"
          size="sm"
          @click="selectedDate = ''"
          >Terug naar totaal overzicht</b-button
        >
        <day-calendar :date="selectedDate" :data="dayData" />
      </div>
      <calendar-view
        v-if="selectedDate === ''"
        :show-date="showDate"
        :display-period-uom="displayPeriodUom"
        :items="calendarData"
        :starting-day-of-week="1"
        locale="nl"
        class="theme-default holiday-us-traditional holiday-us-official mb-5"
        @click-date="openDayView"
      >
        <template #header="{ headerProps }">
          <calendar-view-header
            slot="header"
            :header-props="headerProps"
            @input="setShowDate"
          />
        </template>
        <template #weekNumber="{numberInYear}">
          <calendar-view-day slot="weekNumber" @input="numberInYear">
            {{ numberInYear }}
          </calendar-view-day>
        </template>
      </calendar-view>
    </b-container>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

// The next two lines are optional themes
import dayjs from 'dayjs'
import '@/assets/scss/simple-calendar.scss'

import { testdata } from './calendardata'
import { getTimePeriods } from '@/services/TimePeriods'
import { morning, afternoon, evening } from './timeWindowDaySections'

import DayCalendar from './DayCalendar.vue'

export default {
  name: 'Services',
  components: {
    CalendarView,
    CalendarViewHeader,
    DayCalendar
  },
  data: function() {
    return {
      showDate: new Date(),
      displayPeriodUom: 'month',
      items: testdata,
      data: [],
      displayOptions: [
        { value: 'week', text: 'Week' },
        { value: 'month', text: 'Maand' },
        { value: 'year', text: 'Jaar' }
      ],
      loading: false,
      nextDataRequest: null,
      selectedDate: '',
      dayData: [],
      warehouseID: 'Asse',
      warehouses: ['Asse', 'Bekkevoort', 'Leuven']
    }
  },
  computed: {
    providedWarehouseId() {
      return this.$route.query.warehouse
    },
    calendarData: function() {
      // Month data
      if (this.displayPeriodUom === 'month') return this.monthData
      if (this.displayPeriodUom === 'week') return this.weekData
      return this.data
    },
    monthData: function() {
      if (this.data === []) return
      return this.formatMonthData(this.data)
    },
    weekData: function() {
      if (this.data === []) return
      return this.formatWeekData(this.data)
    },

    timeWindows: function() {
      return [...new Set(this.data.map(timeWindow => timeWindow.BusinessHour))]
    }
  },
  watch: {
    showDate: async function(date) {
      await this.getData({ date: dayjs(date).format('YYYY-MM-DD') })
    },
    warehouseID: async function() {
      await this.getData({ date: this.showDate })
    }
  },
  created: async function() {
    await this.getData({ date: this.showDate })
    if (
      this.providedWarehouseId &&
      this.warehouses.includes(this.providedWarehouseId)
    ) {
      this.warehouseID = this.providedWarehouseId
    }
  },
  methods: {
    openDayView(date) {
      this.dayData = this.filterDayData(this.data, date)
      this.selectedDate = date
    },
    getData: async function({ date, warehouseID = this.warehouseID } = {}) {
      if (this.loading) {
        this.nextDataRequest = { date, warehouseID }
        return
      }

      this.loading = true

      while (this.loading) {
        const requestData = this.nextDataRequest ?? { date, warehouseID }

        this.data = await this.fetchData({ requestData })

        if (this.nextDataRequest === null) {
          this.loading = false
        }

        if (this.nextDataRequest === requestData) {
          this.nextDataRequest = null
          this.loading = false
        }
      }
    },

    fetchData: async function({ requestData }) {
      const startDate = dayjs(requestData.date)
        .subtract(10, 'days')
        .format('YYYY-MM-DD')
      const endDate = dayjs(requestData.date)
        .add(40, 'days')
        .format('YYYY-MM-DD')
      const result = await getTimePeriods({
        startDate,
        endDate,
        warehouseID: requestData.warehouseID
      })
      return result.Collection
    },
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    setShowDate(d) {
      this.showDate = d
    },

    filterDayData(data, date) {
      const dayData = []

      for (const period of data) {
        if (
          dayjs(period.DateBusinessDay, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
            dayjs(date).format('YYYY-MM-DD') &&
          (period.VisibleInFront || period.UsedCheckPoints > 0)
        ) {
          dayData.push(period)
        }
      }
      return dayData
    },

    formatWeekData(data) {
      const calendarData = []

      function getDaySection(timeWindow) {
        if (morning.includes(timeWindow)) return '1. morning'
        if (afternoon.includes(timeWindow)) return '2. afternoon'
        if (evening.includes(timeWindow)) return '3. evening'
        return null
      }

      data.forEach(function(timeWindow) {
        // Add Day section for grouping by day secion (morning, afternoon, evening)
        timeWindow.daySection = getDaySection(timeWindow.BusinessHour)

        if (
          !calendarData.some(
            object =>
              dayjs(object.startDate).format('DD-MM-YYYY') ===
                dayjs(timeWindow.DateBusinessDay).format('DD-MM-YYYY') &&
              object.daySection === timeWindow.daySection
          )
        ) {
          const sameDayOpenOrUsedTimeWindows = data.filter(
            filteredTimeWindow =>
              filteredTimeWindow.DateBusinessDay ==
                timeWindow.DateBusinessDay &&
              getDaySection(filteredTimeWindow.BusinessHour) ===
                timeWindow.daySection &&
              (filteredTimeWindow.VisibleInFront ||
                filteredTimeWindow.UsedCheckPoints > 0)
          )

          if (sameDayOpenOrUsedTimeWindows.length > 0) {
            const load = Math.round(
              sameDayOpenOrUsedTimeWindows
                .map(tw => tw.PercentageUsedCheckPoints)
                .reduce((prev, next) => prev + next) /
                sameDayOpenOrUsedTimeWindows.length
            )

            const employees = Math.round(
              sameDayOpenOrUsedTimeWindows
                .map(tw => tw.AvailableUsers)
                .reduce((prev, next) => prev + next) /
                sameDayOpenOrUsedTimeWindows.length
            )

            let classes = `
            ${load >= 100 ? 'bg-darkred' : ''}
            ${load >= 90 && load <= 100 ? 'bg-danger' : ''}
            ${load >= 70 && load <= 90 ? 'bg-warning' : ''}
            ${load < 70 ? 'bg-success' : ''}
            `

            calendarData.push({
              id: `${timeWindow.DateBusinessDay}-${timeWindow.daySection}`,
              startDate: timeWindow.DateBusinessDay,
              title: `${sameDayOpenOrUsedTimeWindows.length}-${load}%`,
              load,
              classes,
              daySection: timeWindow.daySection,
              employees
            })
          }
        }
      })
      return calendarData
    },
    formatMonthData(data) {
      const calendarData = []

      data.forEach(function(timeWindow) {
        if (
          !calendarData.some(
            object =>
              dayjs(object.startDate).format('DD-MM-YYYY') ===
              dayjs(timeWindow.DateBusinessDay).format('DD-MM-YYYY')
          )
        ) {
          const sameDayOpenOrUsedTimeWindows = data.filter(
            filteredTimeWindow =>
              dayjs(filteredTimeWindow.DateBusinessDay).format('DD-MM-YYYY') ===
                dayjs(timeWindow.DateBusinessDay).format('DD-MM-YYYY') &&
              (filteredTimeWindow.VisibleInFront ||
                filteredTimeWindow.UsedCheckPoints > 0)
          )

          const load = Math.round(
            sameDayOpenOrUsedTimeWindows
              .map(tw => tw.PercentageUsedCheckPoints)
              .reduce((prev, next) => prev + next) /
              sameDayOpenOrUsedTimeWindows.length
          )

          const employees = Math.round(
            sameDayOpenOrUsedTimeWindows
              .map(tw => tw.AvailableUsers)
              .reduce((prev, next) => prev + next) /
              sameDayOpenOrUsedTimeWindows.length
          )

          let classes = `
          ${load >= 100 ? 'bg-darkred' : ''}
          ${load >= 90 && load <= 100 ? 'bg-danger' : ''}
          ${load >= 70 && load <= 90 ? 'bg-warning' : ''}
          ${load < 70 ? 'bg-success' : ''}
          `

          calendarData.push({
            id: timeWindow.DateBusinessDay,
            startDate: timeWindow.DateBusinessDay,
            title: `${sameDayOpenOrUsedTimeWindows.length}-${load}%`,
            load,
            classes,
            employees
          })
        }
      })
      return calendarData
    }
  }
}
</script>

<style>
.cv-week {
  min-height: 90px;
}
.bg-darkred {
  background-color: #981a26 !important;
}
</style>
