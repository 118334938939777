import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=ccc87478&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormRadio, BFormRadioGroup, BCol, BFormSelect, BRow, BButton, BContainer} from 'bootstrap-vue'
    installComponents(component, {BFormRadio, BFormRadioGroup, BCol, BFormSelect, BRow, BButton, BContainer})
    

export default component.exports