<template>
  <div class="w-100">
    <h4 class="cv-day-number">{{ formattedDate }}</h4>
    <div v-if="!data.length" class="text-center pb-2">
      <strong
        >Het lijkt er op dat er voor deze dag geen gegevens bekend zijn,
        controleer in het week of maand overzicht of dit een openings dag
        is</strong
      >
    </div>
    <div
      v-for="period of data"
      :key="period.BusinessHour"
      :class="{
        'calendar-entry': true,
        'bg-darkred': period.PercentageUsedCheckPoints >= 100,
        'bg-danger':
          period.PercentageUsedCheckPoints >= 90 &&
          period.PercentageUsedCheckPoints <= 100,
        'bg-warning':
          period.PercentageUsedCheckPoints >= 70 &&
          period.PercentageUsedCheckPoints <= 90,
        'bg-success': period.PercentageUsedCheckPoints < 70
      }"
    >
      {{ period.Start }} - {{ period.End }} <span class="pl-3"></span>
      {{ period.PercentageUsedCheckPoints }}%
      {{ period.AvailableUsers }} personen
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'CalendarDayView',
  props: {
    date: {
      required: true,
      type: Date
    },
    data: {
      required: true,
      type: Array
    }
  },
  computed: {
    formattedDate() {
      return dayjs(this.date).format('YYYY-MM-DD')
    },
    sortedList() {
      let sortedData = this.data
      sortedData.sort((a, b) => parseFloat(a.Ranking) - parseFloat(b.Ranking))

      return sortedData
    }
  }
}
</script>

<style scoped>
.calendar-entry {
  display: block;
  width: 100%;
  background-color: #025ea5;
  border-radius: 0.5em;
  padding-left: 10px;
  color: white;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}
.bg-darkred {
  background-color: #981a26 !important;
}
</style>
